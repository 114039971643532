import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Run effects after router pathname change to scroll to top
// Must be rendered inside of router
// https://reactrouter.com/web/guides/scroll-restoration
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const handlePopState = () => {
            window.scrollTo(0, 0);
        };
        // this will be executed after async events
        setTimeout(() => window.scrollTo(0, 0), 0);

        // Scroll to top on route change
        window.scrollTo(0, 0);

        // Handle browser navigation events (back/forward)
        window.addEventListener('popstate', handlePopState);

        // Cleanup event listener
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [pathname]);

    return null;
};

export default ScrollToTop;
