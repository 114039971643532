/** Theme names. Should match the values that come from Sitecore */
export const THEME_NAMES = {
    LIGHT: 'Light',
    MEDIUM: 'Medium',
    DARK: 'Dark',
    ACCENT: 'Accent',
    ECO: 'Eco',
    IMAGE_LIGHT: 'Image-Light',
    IMAGE_MEDIUM: 'Image-Medium',
    IMAGE_DARK: 'Image-Dark',
    IMAGE_ACCENT: 'Image-Accent',
    IMAGE_ECO: 'Image-Eco',
    SOLID_LIGHT: 'Solid-Light',
    SOLID_DARK: 'Solid-Dark',
    SOLID_MEDIUM: 'Solid-Medium',
    SOLID_ACCENT: 'Solid-Accent',
    SOLID_ECO: 'Solid-Eco',
    COLOR_IMAGE_MEDIUM: 'Color-Image-Medium',
    MHF_MAROON: 'Mhf-Maroon',
};

export const DEFAULT_THEME_NAME = THEME_NAMES.LIGHT;

export const THEME_OPTIONS = Object.freeze({
    /** White Background, with a default theme background image */
    [THEME_NAMES.LIGHT]: {
        bg: 'light',
        isDark: false,
    },

    /** Gray Background, with a default theme background image */
    [THEME_NAMES.MEDIUM]: {
        bg: 'medium',
        isDark: false,
    },

    /** Maroon Background, with a default theme background image */
    [THEME_NAMES.DARK]: {
        bg: 'dark',
        isDark: true,
    },

    /** Blue Background, with a default theme background image */
    [THEME_NAMES.ACCENT]: {
        bg: 'accent',
        isDark: true,
    },

    /** Green Background, with a default theme background image */
    [THEME_NAMES.ECO]: {
        bg: 'eco',
        isDark: true,
    },

    /** Monochrome line Image, with White background */
    [THEME_NAMES.IMAGE_LIGHT]: {
        bg: 'image-light',
        isDark: false,
    },

    /** Monochrome line Image, with Gray background */
    [THEME_NAMES.IMAGE_MEDIUM]: {
        bg: 'image-medium',
        isDark: false,
    },

    /** Monochrome line Image, with Maroon background */
    [THEME_NAMES.IMAGE_DARK]: {
        bg: 'image-dark',
        isDark: true,
    },

    /** Monochrome line Image, with Blue background */
    [THEME_NAMES.IMAGE_ACCENT]: {
        bg: 'image-accent',
        isDark: true,
    },

    /** Monochrome line Image, with Green background */
    [THEME_NAMES.IMAGE_ECO]: {
        bg: 'image-eco',
        isDark: true,
    },

    /** Solid White background */
    [THEME_NAMES.SOLID_LIGHT]: {
        bg: 'light',
        isDark: false,
    },

    /** Solid Gray background */
    [THEME_NAMES.SOLID_MEDIUM]: {
        bg: 'medium',
        isDark: false,
    },

    /** Solid Maroon background */
    [THEME_NAMES.SOLID_DARK]: {
        bg: 'dark',
        isDark: true,
    },

    /** Solid Blue background */
    [THEME_NAMES.SOLID_ACCENT]: {
        bg: 'accent',
        isDark: true,
    },

    /** Solid Green background */
    [THEME_NAMES.SOLID_ECO]: {
        bg: 'eco',
        isDark: true,
    },

    /** Colored Animal Image, with Gray background */
    [THEME_NAMES.COLOR_IMAGE_MEDIUM]: {
        bg: 'color-image-medium',
        isDark: false,
    },

    /** Dark Maroon background */
    [THEME_NAMES.MHF_MAROON]: {
        bg: 'mhf-maroon',
        isDark: true,
    },
});
