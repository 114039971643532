import React from 'react';
import PropTypes from 'prop-types';
import ThemeContext from './ThemeContext';
import { THEME_OPTIONS, DEFAULT_THEME_NAME } from './constants';
import { getTheme } from './utilities';

const ThemeProvider = ({ name, children }) => {
    const theme = React.useMemo(() => getTheme(name), [name]);

    return (
        <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
    );
};

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,

    /** The name of the theme to use */
    name: PropTypes.oneOf(Object.keys(THEME_OPTIONS)),
};

ThemeProvider.defaultProps = {
    name: DEFAULT_THEME_NAME,
};

export default ThemeProvider;
