import React from 'react';
import './Spinner.scss';

const Spinner = () => (
    <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
    </div>
);

export const SpinnerInline = () => (
    <div className="spinner spinner--inline">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
    </div>
);

export default Spinner;
