import { THEME_NAMES, THEME_OPTIONS, DEFAULT_THEME_NAME } from './constants';

export const isValidThemeName = (name) =>
    Object.values(THEME_NAMES).includes(name);

export const getTheme = (themeName) => {
    const setThemeName = isValidThemeName(themeName)
        ? themeName
        : DEFAULT_THEME_NAME;
    return THEME_OPTIONS[setThemeName];
};

/**
 * Function - themeCheckerForBgImg
 * @param {String} themeName
 * @returns {Boolean}
 * returns true, when ThemeName is either "Light", "Dark", "Accent", "Medium", or "Eco" else false
 * Using the return value the image will be applied
 */
export const themeCheckerForBgImg = (themeName) => {
    // themeName is a case sensitive theme name sent from sitecore
    switch (themeName) {
        case THEME_NAMES.LIGHT: {
            return true;
        }
        case THEME_NAMES.DARK: {
            return true;
        }
        case THEME_NAMES.MEDIUM: {
            return true;
        }
        case THEME_NAMES.ACCENT: {
            return true;
        }
        case THEME_NAMES.ECO: {
            return true;
        }
        default:
            return false;
    }
};
